<template>
  <div class="socialaccounts">
    <div class="content">
      <div class="title">
        <span>Connected social accounts</span>
      </div>
      <div class="accounts">
        <div class="account">
          <div class="icon">
            <img src="../assets/icons/Social-round-instagram.svg" />
          </div>
          <div class="name">
            <span class="active">Instagram</span>
          </div>
          <div class="check">
            <img src="../assets/icons/Check.svg" />
          </div>
        </div>
        <div class="account">
          <div class="icon">
            <img src="../assets/icons/Social-round-facebook.svg" />
          </div>
          <div class="name">
            <span>Facebook</span>
          </div>
        </div>
        <div class="account">
          <div class="icon">
            <img src="../assets/icons/Social-round-twitter.svg" />
          </div>
          <div class="name">
            <span>Twitter</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialAccounts"
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.socialaccounts {
  width: 100%;
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    padding: 60px 184px;
    color: $bg-dark;
    .title {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -1.025px;
      }
    }
    .accounts {
      margin-top: 24px;
      .account {
        display: flex;
        padding: 8px 16px;
        background: $gray;
        border-radius: 25px;
        margin-bottom: 32px;
        .icon {
          width: 30px;
          height: 30px;
          img {
            object-fit: cover;
          }
        }
        .name {
          flex: 1;
          margin-left: 16px;
          span {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.22px;
            color: #3b3b3b;
            &.active {
              color: $green;
              font-weight: bold;
            }
          }
        }
        .check {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #7cc576;
          overflow: hidden;
          border-radius: 50%;
          img {
            object-fit: cover;
          }
        }
      }
    }
  }
}
</style>
